<template>
  <!--begin::details View-->
  <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
    <!--begin::Card header-->
    <div class="card-header cursor-pointer">
      <!--begin::Card title-->
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">Associate Client List</h3>
      </div>
      <!--end::Card title-->
      <!--begin::Card toolbar-->
      <div class="card-toolbar">
        <div class="d-flex flex-row flex-column-fluid">
          <div class="d-flex">
            <select
              name="licensedDistributor"
              class="form-select form-select-solid"
              as="select"
              v-model="licensedDistClient.clientId"
            >
              <option value="0">Add A Client...</option>
              <option
                v-for="item in licensedDistClientsDDL"
                :key="item"
                :label="item.name"
                :value="item.clientId"
              >
                {{ item.name }}
              </option>
            </select>
          </div>

          <div class="d-flex">
            <a href="#" @click="addClient()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="42"
                height="42"
                fill="#999"
                class="bi bi-plus-square-fill"
                viewBox="0 0 16 16"
              >
                <path
                  d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>

      <!--end::Card toolbar-->
      <!--begin::Action-->
    </div>
    <!--end::Action-->
    <!--begin::Card header-->

    <!--begin::Card body-->
    <div class="card-body pt-0" v-if="isDataReady">
      <Datatable
        :table-data="tableData"
        :table-header="tableHeader"
        :enable-items-per-page-dropdown="true"
      >
        <template v-slot:cell-name="{ row: client }">
          {{ client.name }}
        </template>
        <template v-slot:cell-primaryContactName="{ row: client }">
          {{ client.primaryContactName }}
        </template>
        <template v-slot:cell-primaryContactEmail="{ row: client }">
          {{ client.primaryContactEmail }}
        </template>
        <template v-slot:cell-numOfEmployees="{ row: client }">
          {{ client.numOfEmployees }}
        </template>
        <template v-slot:cell-actions="{ row: client }">
          <router-link
            :to="{
              name: 'client-overview',
              params: {
                clientid: Number(client.clientId),
              },
            }"
            class="
              btn btn-icon btn-bg-light btn-active-color-primary btn-sm
              me-1
            "
          >
            <span class="svg-icon svg-icon-3">
              <inline-svg src="../media/icons/duotune/art/art005.svg" /> </span
          ></router-link>
          <a
            href="#"
            class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
            @click="removeClient(client.clientId)"
          >
            <span class="svg-icon svg-icon-3">
              <inline-svg src="../media/icons/duotune/general/gen027.svg" />
            </span>
          </a>
        </template>
      </Datatable>
    </div>
    <div v-else>
      <TableSkeleton></TableSkeleton>
    </div>
    <!--end::Card body-->
  </div>
  <!--end::details View-->
</template>
<script lang="ts">
import {
  defineComponent,
  ref,
  computed,
  onUpdated,
  watch,
  onMounted,
} from "vue";
import Datatable from "@/components/datatable/KTDatatable.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Actions } from "@/store/enums/StoreEnums";
import { Client } from "@/core/types/ClientTypes";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import TableSkeleton from "@/components/widgets/skeletons/TableSkeleton.vue";
import { boolean } from "yup/lib/locale";
import { LicensedDistClient } from "../../core/types/LicensedDistributorTypes";

export default defineComponent({
  name: "incline-ld-associate-clients",
  props: ["accountid"],
  components: {
    Datatable,
    TableSkeleton,
  },
  setup(props) {
    const store = useStore();
    const currentUser = store.getters.currentUser;
    const route = useRoute();
    const routeName = computed(() => route.name);
    let displayModal = ref<boolean>(false);
    const isDataReady = ref(false);
    const licensedDistAssociateInfo = computed(() => {
      return store.getters.getLicensedDistributorAssociateInfo(
        String(props.accountid)
      );
    });
    const licensedDistClient = ref<LicensedDistClient>({
      clientId: 0,
      licensedDistributorId:
        licensedDistAssociateInfo.value.licensedDistributorId,
    });
    const tableHeader = ref([
      {
        name: "Name",
        key: "name",
        sortable: true,
      },
      {
        name: "Primary Contact",
        key: "primaryContactName",
        sortable: true,
      },
      {
        name: "Email",
        key: "primaryContactEmail",
        sortable: true,
      },
      {
        name: "# of Employees",
        key: "numOfEmployees",
        sortable: true,
      },
      {
        name: "Actions",
        key: "actions",
      },
    ]);

    const tableData = computed(() => {
      return store.getters.getClientList;
    });

    const initCustomers = ref<Array<Client>>([]);
    const licensedDistClientsDDL = computed(() => {
      return store.getters.getLicensedDistClientsDDL;
    });

    onMounted(() => {
      store
        .dispatch(Actions.GET_LICENSED_DIST_ASSOCIATE_CLIENTS, props.accountid)
        .then(() => {
          //
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "There was a problem retrieving clients.",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });

      store
        .dispatch(
          Actions.GET_LICENSED_DISTRIBUTOR_CLIENTS_DDL,
          licensedDistAssociateInfo.value.distAspNetUserId
        )
        .then(() => {
          //
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText:
              "There was a problem retrieving clients for dropdown.",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    });

    onUpdated(() => {
      initCustomers.value.splice(0, tableData.value.length, ...tableData.value);
    });

    watch(tableData, () => {
      isDataReady.value = true;
    });

    function openModal() {
      displayModal.value = true;
    }

    const search = ref<string>("");
    const searchItems = () => {
      tableData.value.splice(0, tableData.value.length, ...initCustomers.value);
      if (search.value !== "") {
        let results: Array<Client> = [];
        for (let j = 0; j < tableData.value.length; j++) {
          if (searchingFunc(tableData.value[j], search.value)) {
            results.push(tableData.value[j]);
          }
        }
        tableData.value.splice(0, tableData.value.length, ...results);
      }
    };

    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (
          !Number.isInteger(obj[key]) &&
          !(typeof obj[key] === boolean) &&
          !(typeof obj[key] === "object")
        ) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    };

    function addClient() {
      store
        .dispatch(
          Actions.POST_LICENSED_DISTRIBUTOR_ASSOC_CLIENT,
          licensedDistClient.value
        )
        .then(() => {
          Swal.fire({
            text: "Success. Client has been added.",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Continue",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          }).then(function () {
            licensedDistClient.value.clientId = 0;
          });
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    }

    function removeClient(clientId) {
      licensedDistClient.value.clientId = clientId;

      Swal.fire({
        title: "Are you sure you want to remove this client?",
        showCancelButton: true,
        confirmButtonText: "Yes. Remove",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          store
            .dispatch(
              Actions.REMOVE_LICENSED_DISTRIBUTOR_ASSOC_CLIENT,
              licensedDistClient.value
            )
            .then(() => {
              Swal.fire({
                text: "Success. Client has been removed.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Continue",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              }).then(function () {
                licensedDistClient.value.clientId = 0;
              });
            })
            .catch(() => {
              Swal.fire({
                text: store.getters.getErrors[0],
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Try again!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });
        }
      });
    }

    return {
      licensedDistAssociateInfo,
      currentUser,
      routeName,
      licensedDistClientsDDL,
      licensedDistClient,
      isDataReady,
      initCustomers,
      tableData,
      tableHeader,
      search,
      displayModal,
      searchItems,
      openModal,
      addClient,
      removeClient,
    };
  },
});
</script>
